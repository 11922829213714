/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.1.4/dist/jquery.min.js
 * - /npm/popper.js@1.11.0/dist/umd/popper.min.js
 * - /npm/bootstrap@4.0.0/dist/js/bootstrap.min.js
 * - /npm/featherlight@1.7.14/release/featherlight.min.js
 * - /gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/jquery-smooth-scroll@2.2.0/jquery.smooth-scroll.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
